import React, { useState, useEffect } from "react";
import "./App.css";
import secretSound from "./secret_sound.mp3";
import { BsLock, BsUnlock } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import { GrAchievement } from "react-icons/gr";

const App = () => {
  const [userInput, setUserInput] = useState({
    nb1: "",
    nb2: "",
    nb3: "",
    nb4: "",
  });
  const [isLocked, setIsLocked] = useState(false);
  const [isRightCode, setIsRightCode] = useState(false);

  const [isSc1Correct, setIsSc1Correct] = useState(false);
  const [isSc2Correct, setIsSc2Correct] = useState(false);
  const [isSc3Correct, setIsSc3Correct] = useState(false);
  const [isSc4Correct, setIsSc4Correct] = useState(false);
  const [audio] = useState(new Audio(secretSound));

  const handleChange = ({ target: { name, value } }) => {
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      [name]: value,
    }));

    const secretCode = { sc1: 2, sc2: 8, sc3: 4, sc4: 6 };
    if (parseInt(value) === secretCode.sc1) {
      setIsSc1Correct(!isSc1Correct);
    }
    if (parseInt(value) === secretCode.sc2) {
      setIsSc2Correct(!isSc2Correct);
    }
    if (parseInt(value) === secretCode.sc3) {
      setIsSc3Correct(!isSc3Correct);
    }
    if (parseInt(value) === secretCode.sc4) {
      setIsSc4Correct(!isSc4Correct);
    }
  };

  const playSound = () => {
    audio.play();
  };

  const submitCode = () => {
    const secretCode = { sc1: 2, sc2: 8, sc3: 4, sc4: 6 };

    if (
      secretCode.sc1 === parseInt(userInput.nb1, 10) &&
      secretCode.sc2 === parseInt(userInput.nb2, 10) &&
      secretCode.sc3 === parseInt(userInput.nb3, 10) &&
      secretCode.sc4 === parseInt(userInput.nb4, 10)
    ) {
      setIsLocked(!isLocked);
      playSound();
      setTimeout(() => {
        window.location.href =
          "https://www.leetchi.com/fr/c/noel-cam-1720285?utm_source=copylink&utm_medium=social_sharing";
      }, 3500);
    } else {
      setIsRightCode(true);
      setTimeout(() => {
        setIsRightCode(false);
        setUserInput({
          nb1: "",
          nb2: "",
          nb3: "",
          nb4: "",
        });
        setIsSc1Correct(false);
        setIsSc2Correct(false);
        setIsSc3Correct(false);
        setIsSc4Correct(false);
      }, 2500);
    }
  };

  return (
    <div className="container">
      <div className="inputs">
        <div className="input-box">
          <input
            type="text"
            name="nb1"
            value={userInput.nb1}
            onChange={handleChange}
            maxLength="1"
            id="nb1"
          />
          <div className={`circle ${isSc1Correct ? "green-bg" : ""}`}></div>
        </div>
        <div className="input-box">
          <input
            type="text"
            name="nb2"
            value={userInput.nb2}
            onChange={handleChange}
            maxLength="1"
            id="nb2"
          />
          <div className={`circle ${isSc2Correct ? "green-bg" : ""}`}></div>
        </div>

        <div className="input-box">
          <input
            type="text"
            name="nb3"
            value={userInput.nb3}
            onChange={handleChange}
            maxLength="1"
            id="nb3"
          />
          <div className={`circle ${isSc3Correct ? "green-bg" : ""}`}></div>
        </div>

        <div className="input-box">
          <input
            type="text"
            name="nb4"
            value={userInput.nb4}
            onChange={handleChange}
            maxLength="1"
            id="nb4"
          />
          <div className={`circle ${isSc4Correct ? "green-bg" : ""}`}></div>
        </div>
      </div>
      <div className="button">
        <button
          type="submit"
          onClick={submitCode}
          className="red-gradient-button"
        >
          {isLocked ? <BsUnlock style={{ color: "blue" }} /> : <BsLock />}
        </button>
      </div>
      {isRightCode ? (
        <div className="alert">
          <p>
            <IoWarningOutline />
            Mauvais code ! Mouahahaha
          </p>
        </div>
      ) : (
        ""
      )}
      {isLocked && (
        <div className="alert success">
          <p>
            <GrAchievement />
            Joyeux Noël !!!
          </p>
        </div>
      )}
    </div>
  );
};

export default App;
